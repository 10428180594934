import React from "react";
import Imagen from "../Imagen";

export enum CameraCardImagePosition {
    Left, Right
}
;
type CamaraCardProps = {
    children?: React.ReactNode;
    image;
    imageAlt: string;
    imagePosition?: CameraCardImagePosition;
    imageClass?: string;
    containerClass?: string;
};
export const CamaraCard = ({
    children, image, imageAlt, imagePosition = CameraCardImagePosition.Left, imageClass = "", containerClass = ""
}: CamaraCardProps) => {
    return (
        <div className="p-2 my-4 border-l-2 border-r-2 border-opacity-25 border-indigo-400">

            <figure className={`sm:flex p-4 sm:p-0 rounded-xl ${(imagePosition == CameraCardImagePosition.Right) && "flex-row-reverse"} ${containerClass}`}>

                <Imagen
                    className={`w-full h-48 sm:w-1/2 sm:h-auto mx-auto rounded ${imageClass} ring-2 ring-gray-500 ring-opacity-25`}
                    src={image}
                    alt={imageAlt} />

                <div className="sm:w-1/2">
                    {children}
                </div>
            </figure>
        </div>
    );
};
