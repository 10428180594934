// https://stackoverflow.com/a/56508865

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Imagen = ({
    src, alt, className
}) => (
    <StaticQuery
        query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `}
        render={data => {
            const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(src);
            });

            if (!image) {
                return null;
            }

            const imageData = getImage(image.node.childImageSharp);

            return <GatsbyImage
                className={className}
                alt={alt}
                image={imageData}
                objectFit="cover"
            />;
        }}
    />
);

export default Imagen;