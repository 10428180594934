import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export enum BadgeIconPosition {
    Left, Right
};

type BadgeProps = {
    children: React.ReactNode,
    className: string,
    icon?: IconProp,
    iconPosition?: BadgeIconPosition,
    iconSize?: number,
    iconClassName?: string
};

const Badge = ({
    children,
    className,
    icon, iconPosition = BadgeIconPosition.Left, iconSize = 16, iconClassName
}: BadgeProps) => {
    return (
        <span className={`inline-flex items-center justify-center px-2 py-1 mr-2 text-sm leading-none font-semibold rounded-full ${iconPosition == BadgeIconPosition.Right ? "flex-row-reverse" : ""} ${className}`}>
            {
                icon &&
                <FontAwesomeIcon
                    icon={icon}
                    fixedWidth={true}
                    width={iconSize} height={iconSize}
                    className={`${iconPosition == BadgeIconPosition.Left ? "mr-1" : "ml-1"} ${iconClassName}`}
                />
            }
            {children}
        </span>
    );
};

export default Badge;