import React, { useRef } from "react";
import MainLayout from "../../layout/MainLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faDollarSign, faBinoculars, faMoon, faMicrophone, faTemperatureHigh, faFireAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import Badge, { BadgeIconPosition } from "../../components/Badge";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { CamaraCard, CameraCardImagePosition } from "../../components/camaras/CameraCard";

const CamarasExterioresPage = () => {
    return (
        <MainLayout title="Cámaras exteriores">
            <h1 className="text-5xl">Cámaras de videovigilancia de exterior</h1>

            <div className="pb-6">
                <p className="whitespace-pre-line">Las cámaras de <strong>videovigilancia externa</strong> de MOBOTIX&copy; cuentan con protección <OutboundLink title="Ver artículo de Wikipedia sobre la protección IP" href="https://es.wikipedia.org/wiki/Grado_de_protecci%C3%B3n_IP" target="_blank" rel="noopener noreferrer"><em>IP66</em></OutboundLink> para poder resistir cualquier tipo de entorno adverso: lluvia, nieve o sol.</p>
                <p className="whitespace-pre-line">También cuentan con un <strong>almacenamiento local</strong> (con tarjeta MicroSD interna) para poder guardar grabaciones, aunque también es posible conectarlas a un <OutboundLink title="Ver artículo de Wikipedia sobre los NAS" href="https://es.wikipedia.org/wiki/Almacenamiento_conectado_en_red" target="_blank" rel="noopener noreferrer"><em>NAS</em></OutboundLink> en tu propia red o subirlas a la nube.</p>
            </div>

            <div className="grid gap-4">
                <CamaraCard
                    image="m16_2.jpg"
                    imageAlt="Cámara MOBOTIX&copy; M16"
                >
                    <div className="pt-6 md:p-8 text-center md:text-left space-y-4">

                        <div className="flex flex-row items-center">
                            <h3 className={`text-2xl font-bold mb-2`}>
                                M16 AllroundDual
                            </h3>

                            <span className="text-sm font-light ml-3">
                                <FontAwesomeIcon icon={faStar} className="text-yellow-300" size="1x" />&nbsp;
                                La más vendida
                            </span>
                        </div>

                        <blockquote>
                            <p className="font-sem whitespace-pre-line">
                                Lo original sigue siendo original: El más reciente sucesor de nuestros exitosos modelos de cámara dual M1 a M12 es robusto y resistente a la intemperie y ofrece, además de módulos de sensor intercambiables, la tecnología de sistema más avanzada de MOBOTIX&copy;, por lo que sigue siendo único en cuanto a potencia, funcionalidad y diseño.
                            </p>

                            <br />

                            <Badge className="ring-2 ring-indigo-700 ring-opacity-50 text-gray-700" icon={faMicrophone}>
                                Microfono y altavoz
                            </Badge>

                            <Badge className="ring-2 ring-indigo-400 ring-opacity-75 text-gray-700" icon={faFireAlt}>
                                Visión térmica
                            </Badge>

                            <Badge className="ring-2 ring-indigo-400 ring-opacity-75 text-gray-700" icon={faMoon}>
                                Sensor día/noche
                            </Badge>
                        </blockquote>
                    </div>
                </CamaraCard>

                <CamaraCard
                    image="m26_2.jpg"
                    imageAlt="Cámara MOBOTIX&copy; M17"
                    imagePosition={CameraCardImagePosition.Right}
                >
                    <div className="pt-6 md:p-8 text-center md:text-left space-y-4">

                        <div className="flex flex-row items-center">
                            <h3 className={`text-2xl font-bold mb-2`}>
                                M26 Allround
                            </h3>

                            <span className="text-sm font-light ml-3">
                                <FontAwesomeIcon icon={faDollarSign} className="text-green-700" size="1x" />&nbsp;
                                La más económica
                            </span>
                        </div>

                        <blockquote>
                            <p className="font-sem whitespace-pre-line">
                                Nuestro acreditado sistema de cámara Allround para el montaje en techo, pared o mástil, satisface sin problemas las condiciones más exigentes de las clases de protección IP66 e IK10. Equipada con la última tecnología de 6 MP, la M26 puede utilizarse con especial flexibilidad gracias a la amplia selección de lentes.
                            </p>

                            <br />

                            <Badge className="ring-2 ring-indigo-700 ring-opacity-50 text-gray-700" icon={faMicrophone}>
                                Microfono y altavoz
                            </Badge>

                            <Badge className="ring-2 ring-indigo-700 ring-opacity-50 text-gray-700" icon={faBinoculars}>
                                Ángulos de visión de 8° a 180°
                            </Badge>

                            <Badge className="ring-2 ring-indigo-700 ring-opacity-50 text-gray-700" icon={faTemperatureHigh}>
                                Sensor de temperatura
                            </Badge>

                        </blockquote>
                    </div>
                </CamaraCard>

                <CamaraCard
                    image="q26.jpg"
                    imageAlt="Cámara MOBOTIX&copy; Q26"
                    imagePosition={CameraCardImagePosition.Left}
                >
                    <div className="pt-6 md:p-8 text-center md:text-left space-y-4">

                        <div className="flex flex-row items-center">
                            <h3 className={`text-2xl font-bold mb-2`}>
                                Q26 Hemispheric
                            </h3>

                            <span className="text-sm font-light ml-3">
                                <FontAwesomeIcon icon={faCheck} className="text-blue-700" size="1x" />&nbsp;
                                La más flexible
                            </span>
                        </div>

                        <blockquote>
                            <p className="font-sem whitespace-pre-line">
                                Una cámara domo elegante, ultracompacta y resistente a la intemperie. La Q26 utiliza una lente B016 hemisférica, que viene fijada de fábrica y con el enfoque ajustado. Gracias a la vista omnidireccional de 360° para grabar una estancia completa, a la vista panorámica y a las inteligentes herramientas de análisis de vídeo, esta cámara abre posibilidades de aplicación totalmente nuevas.
                            </p>

                            <br />

                            <Badge className="ring-2 ring-indigo-400 ring-opacity-75 text-gray-700" icon={faMoon}>
                                Sensor día o noche
                            </Badge>

                            <Badge className="ring-2 ring-indigo-700 ring-opacity-50 text-gray-700" icon={faBinoculars}>
                                Visión de 360°
                            </Badge>

                            <Badge className="ring-2 ring-indigo-700 ring-opacity-50 text-gray-700" icon={faMicrophone}>
                                Microfono y altavoz
                            </Badge>

                        </blockquote>
                    </div>
                </CamaraCard>

            </div>
        </MainLayout>
    )
};

export default CamarasExterioresPage;